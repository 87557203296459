/*
 * @Description:正则
 * @Author: zjc
 * @Date: 2021-12-07 11:40:26
 * @LastEditTime: 2025-02-28 13:40:58
 * @LastEditors: zjc
 */
const isExternal = (path) => {
  return /^(https?:|mailto:|tel:)/.test(path)
}
//密码校验
const validatePwd = (value) => {
  return /^(?![A-z0-9]+$)(?=.[^%&',;=?$\x22])(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).{8,}$/.test(value)
}
//正整数校验
const isInteger = (value) => {
  return /^([0-9]*)$/.test(value)
}
//数字字母组合
const isAlphanumeric = (value) => {
  return /[\u4e00-\u9fa5/\s+/]|[^a-zA-Z0-9\u4E00-\u9FA5]/g.test(value)
}
// 6-16位数字字母组合
const isGroup = (value) => {
  return /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,16}$/g.test(value)
}
//校验密码必须是6-15位数字字母组合
const checkPassword = (value) => {
  return /^(?!([a-zA-Z]+|\d+)$)[a-zA-Z\d]{6,16}$/.test(value)
}
//邮箱校验规则
const checkEmail = (value) => {
  return /^[A-Za-z0-9+_.-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/.test(value)
}
const isReg = (value) => {
  return /[^a-zA-Z0-9\u4E00-\u9FA5]/g.test(value)
}
const isMobile = (value) => {
  return /^[1]([3-9])[0-9]{9}$/.test(value)
}
const isPc = () => {
  // return navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)

  let userAgent = navigator.userAgent.toLowerCase();
  // 用 test 匹配浏览器信息
  if (/ipad|iphone|midp|rv:1.2.3.4|ucweb|android|windows ce|windows mobile/.test(userAgent)) {
    // console.log('前端是移动端')
    return false;
  } else {
    // console.log('前端是pc端')
    return true;
  }
}

/**
 * 判断是否是微信环境
 * @returns 
 */
const isWechat = () => {
  let iswechat = false
  let ua = window.navigator.userAgent.toLowerCase()
  if (ua.match(/MicroMessenger/i) == 'micromessenger') {
    iswechat = true
  }
  return iswechat
}
export {
  isExternal,
  validatePwd,
  isInteger,
  isAlphanumeric,
  isReg,
  isMobile,
  isPc,
  isGroup,
  isWechat,
  checkPassword,
  checkEmail
}