<!--
 * @Description: 
 * @Author: zjc
 * @Date: 2021-12-07 15:26:42
 * @LastEditTime: 2024-11-18 14:21:08
 * @LastEditors: zjc
-->
<template>
  <div class="arcsin-header">
    <div class="arcsin-header__top">
      <div class="arcsin-header__top__left">
        <img :src="orgInfo.orgLogo?orgInfo.orgLogo:defaultLogo" />
        <div class="title">{{ orgInfo.orgShortName || libraryTitle}}</div>
      </div>
      <div class="arcsin-header__top__right">

        <div class=" arcsin-header__top__right__item">
          <div class="staging" @click="openClick"
            v-if="state.configData.configValue===1 && state.isAuthCode.includes('BIG_SCREEN')">
            <img :src="getAssetUrl('icon/big.png')" alt="" width="18" height="18"
              style="margin-right:4px;">
            数据大屏
          </div>
          <div class="staging" @click="bookRef.init()">
            <img :src="getAssetUrl('icon/icon_tsgp.png')" alt="" width="18" height="18"
              style="margin-right:4px;">
            图书馆配
          </div>
          <!-- <div class="notice">
            <img :src="getAssetUrl('icon/icon_inbox.png')" alt="" width="20" height="20">
            <img :src="getAssetUrl('icon/icon-circle-question.png')" width="20" height="20"
              style="margin-left:20px;" alt="">
          </div> -->
          <el-dropdown>
            <span>
              <img :src="getAssetUrl('defaultUser.png')" width="28"
                style="vertical-align: middle;border-radius: 100%;margin-right: 10px;" alt="">
              <span>{{name}}</span>
              <el-icon style="margin-left:5px;">
                <CaretBottom />
              </el-icon>
            </span>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item @click="goIndex('/oms/dashboard')">首页
                </el-dropdown-item>
                <el-dropdown-item @click="createDialog">修改密码</el-dropdown-item>
                <el-dropdown-item @click="loginOut">退出</el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </div>
      </div>
    </div>

    <!-- 修改密码 -->
    <modal-item ref="modal" v-if="dialogData.showDialog" :dialogData="dialogData" :formData="form"
      v-model:form="form" :option="formOption" @row-save="rowSave"></modal-item>

    <!-- 图书馆配 -->
    <book-library ref="bookRef"></book-library>
  </div>
</template>
<script setup>
import ModalItem from '@/components/CRUD/modalItem.vue'
import bookLibrary from './bookLibrary.vue'
import { defaultLogo } from '@/enums/default'
import { getStore } from '@/utils/store'
import { USER_API } from '@/service/user'
import { commonStore } from '@/store/common.js'
import { adminStore } from '@/store/user.js'
import { encryptCode } from '@/utils/jsencrypt.js'
const userStore = adminStore()
import useJump from '@/hooks/useCommon.js'
import { ElMessageBox } from 'element-plus'
import { computed, onMounted, reactive, ref, toRefs } from 'vue'
import useMsg from '@/hooks/useMsg'
import { getAssetUrl, libraryTitle } from '@/theme/theme.js'
const { useNotification } = useMsg()
import { isGroup } from '@/validate/validate.js'

const updatepwd = (rule, value, callback) => {
  if (value != state.form.newPassword) {
    callback(new Error('两次输入不一致，请重新输入'))
  } else if (!isGroup(value)) {
    callback(new Error('请输入6-16位数字+字母组合'))
  } else {
    callback()
  }
}
const updatepwd1 = (rule, value, callback) => {
  if (!value) {
    callback(new Error('请输入新密码'))

  } else if (!isGroup(value)) {
    callback(new Error('请输入6-16位数字+字母组合'))
  } else {
    callback()
  }
}
const modal = ref(null)
const bookRef = ref(null)
const store = commonStore()
const useStore = adminStore()
const isCollapse = computed(() => store.collapse)
const isScreen = computed(() => store.getIsScreen)
const userId = computed(() => useStore.getUserId)
const name = computed(() => useStore.getName)
const { jumpPage } = useJump()
const state = reactive({
  isAuthCode: computed(() => useStore.getAuthCode),
  configData: {
    configValue: 0,
    url: null,
  },
  orgInfo: computed(() => useStore.getOrgInfo),
  // 新增、修改 配置
  formOption: {
    dialogWdith: '600px',
    labelWidth: '80px',
    clearable: true,
    size: 'medium',
    column: [
      {
        prop: 'oldPassword', label: '旧密码', type: 'input', inputType: 'password', placeholder: '请输入旧密码', rules: [
          { required: true, message: "请输入旧密码", trigger: "blur" },
        ]
      },
      {
        prop: 'newPassword', label: '新密码', type: 'input', inputType: 'password', isPassWord: true, placeholder: '请输入新密码', rules: [
          { required: true, trigger: 'blur', validator: updatepwd1 },
        ]
      },
      {
        prop: 'confirmPassword', label: '确认密码', type: 'input', inputType: 'password', isPassWord: true, placeholder: '请输入确认密码', rules: [
          { required: true, message: '请输入确认密码', trigger: 'blur' },
          { required: true, trigger: 'blur', validator: updatepwd }
        ]
      },
    ]
  },
  form: {
  },//新增修改数据
  dialogData: {},//弹窗需要的参数
})
const { dialogData, formOption, form, orgInfo } = toRefs(state)
const getConfig = async () => {
  const { data } = await USER_API.orgConfig()
  for (let i of data) {
    if (i.configKey === 'BIG_SCREEN') {
      state.configData = JSON.parse(i.configValue)
    }
  }
}
//跳转首页
const goIndex = (url) => {
  jumpPage(url)
  useStore.setMenuUrl(url)
}
//打开数据大屏
const openClick = () => {
  const elink = document.createElement('a')
  elink.style.display = 'none'
  elink.href = state.configData.url
  elink.target = '_blank'
  document.body.appendChild(elink)
  elink.click()
  URL.revokeObjectURL(elink.href) // 释放URL 对象
  document.body.removeChild(elink)
}
// 新增、修改 弹窗
const createDialog = () => {
  state.dialogData = {
    title: '修改密码',
    showDialog: true
  }
}
//修改密码
const rowSave = async (val) => {
  try {
    const params = {
      newPassword: encryptCode(state.form.newPassword),
      oldPassword: encryptCode(state.form.oldPassword),
    }
    const { data } = await USER_API.updatePwd(params)
    useNotification({ msg: '修改成功', type: 'success' })
    modal.value.loading = false
    state.dialogData.showDialog = false
  } catch (err) {
    modal.value.loading = false
  }
}
const setCollapse = () => {
  store.UPDATES_IDEBAR_FOLD()
}
// 退出登录
const loginOut = () => {
  ElMessageBox.confirm('此操作将退出登录, 是否继续?', '提示', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    type: 'warning'
  }).then(() => {
    useStore.logOut().then(() => {
      window.location.reload()
    })

  }).catch(() => {
  });
}
onMounted(() => {
  getConfig()
})
</script>
<style lang="scss" scoped>
.hamburger {
  cursor: pointer;
  display: inline-block;
  vertical-align: middle;
  width: 18px;
  height: 18px;
}
.arcsin-header__top {
  // background-color: $--color-primary;
  height: var(--header-height);
  background-size: 100% var(--header-height);
  background-repeat: no-repeat;
}
.arcsin-header__top__middle {
  height: var(--header-height) !important;
}
</style>