/*
 * @Description: 
 * @Author: xlm
 * @Date: 2023-08-02 18:13:06
 * @LastEditTime: 2025-02-28 10:56:07
 * @LastEditors: zjc
 * @LastEditTime: 2024-11-26 15:00:30
 * @FilePath: \zglibrary-pc-ui\src\routerMobile\index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import mobileIndex from '@/viewsMobile/layout/index.vue'

// 移动端项目的路由, 

// 注意, 页面新增路由, name不能重复
export const mobileRoute = [
  {
    path: "/mobile",
    component: mobileIndex,
    children: [
      {
        path: '/mobile/leaderview/login',
        component: () => import('@/viewsMobile/leaderView/login.vue'),
        name: 'mobileLeaderViewLogin',
        meta: {
          title: "登录",
        }
      },
      {
        path: '/mobile/leaderview',
        component: () => import('@/viewsMobile/leaderView/index.vue'),
        name: 'mobileLeaderView',
        meta: {
          title: "领导视图",
        }
      },
      {
        path: '/mobile/leaderview/org',
        component: () => import('@/viewsMobile/leaderView/org.vue'),
        name: 'mobileLeaderViewOrg',
        meta: {
          title: "领导视图",
        }
      },
      {
        path: "/mobile/leaderview/orgUser/:unitId/:unitName/:orgId/:titleType/:subDept",
        component: () => import('@/viewsMobile/leaderView/orgUser.vue'),
        name: 'leaderviewOrgUser',
        meta: {
          title: "领导视图",
        }
      },
      // 新版成员统计页面
      {
        path: "/mobile/leaderview/orgUser1/:unitId/:unitName/:orgId/:titleType/:subDept",
        component: () => import('@/viewsMobile/leaderView/orgUser1.vue'),
        name: 'leaderviewOrgUser1',
        meta: {
          title: "领导视图",
        }
      },
      {
        path: "/mobile/inviteRegister",
        component: () => import('@/viewsMobile/inviteRegister/index.vue'),
        name: 'inviteRegister',
        meta: {
          title: "邀请注册",
        }
      },
      {
        path: "/mobile/emailRegister",
        component: () => import('@/viewsMobile/emailRegister/index.vue'),
        name: 'emailRegister',
        meta: {
          title: "邮箱注册",
        }
      },
      {
        path: "/mobile/download",
        component: () => import('@/viewsMobile/download/index.vue'),
        name: 'download',
        meta: {
          title: "APP下载",
        }
      },
      {
        path: "/mobile/themeDetail",
        component: () => import('@/viewsMobile/themeDetail/index.vue'),
        name: 'themeDetail',
        meta: {
          title: "主题详情",
        }
      },
      {
        path: "/mobile/bookDetail",
        component: () => import('@/viewsMobile/bookDetail/index.vue'),
        name: 'bookDetail',
        meta: {
          title: "图书信息",
        }
      },
      {
        path: "/mobile/documentDetail",
        component: () => import('@/viewsMobile/documentDetail/index.vue'),
        name: 'documentDetail',
        meta: {
          title: "资料信息",
        }
      },
      {
        path: "/mobile/courseDetail",
        component: () => import('@/viewsMobile/courseDetail/index.vue'),
        name: 'courseDetail',
        meta: {
          title: "视频信息",
        }
      },
      {
        path: "/mobile/informationDetail",
        component: () => import('@/viewsMobile/informationDetail/index.vue'),
        name: 'informationDetail',
        meta: {
          title: "资讯详情",
        }
      },
      {
        path: "/mobile/invite",
        component: () => import('@/viewsMobile/invite/index.vue'),
        name: 'invite',
        meta: {
          title: "邀请函",
        }
      },
      {
        path: "/mobile/integral",
        component: () => import('@/viewsMobile/integral/index.vue'),
        name: 'integral',
        meta: {
          title: "积分任务",
          keepAlive: false,
          routeLevel: 0
        }
      },
      {
        path: "/mobile/rule",
        component: () => import('@/viewsMobile/integral/rule.vue'),
        name: 'rule',
        meta: {
          title: "积分说明",
          keepAlive: false,
          routeLevel: 1
        }
      },
      {
        path: "/mobile/integralDetail",
        component: () => import('@/viewsMobile/integral/integralDetail.vue'),
        name: 'integralDetail',
        meta: {
          title: "积分明细",
          keepAlive: true,
          routeLevel: 1
        }
      },
      {
        path: "/mobile/shopping",
        component: () => import('@/viewsMobile/integral/shopping.vue'),
        name: 'shopping',
        meta: {
          title: "积分商城",
          keepAlive: true,
          routeLevel: 1
        }
      },
      {
        path: "/mobile/shoppingDetail",
        component: () => import('@/viewsMobile/integral/shoppingDetail.vue'),
        name: 'shoppingDetail',
        meta: {
          title: "商品详情",
          keepAlive: false,
          routeLevel: 1
        }
      },
      {
        path: "/mobile/conversionDetail",
        component: () => import('@/viewsMobile/integral/conversionDetail.vue'),
        name: 'conversionDetail',
        meta: {
          title: "兑换详情",
          keepAlive: false,
          routeLevel: 2
        }
      },
    ]
  },
]