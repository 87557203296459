/*
 * @Description:
 * @Author: zjc
 * @Date: 2021-12-07 14:56:40
 * @LastEditTime: 2025-02-27 15:34:17
 * @LastEditors: zjc
 */
import request from '@/utils/request'

// 将 JSON 数据转换为查询字符串
const queryString = (jsonData) => {
  if (!jsonData) {
    return ''
  }
  return Object.keys(jsonData)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(jsonData[key]))
    .join("&");
}

// const loginByUserName = (params) => request('post', `/auth/oauth/token`, { user_type: 'O', deviceId: '12345678', grant_type: 'password', scope: 'server', ...params }); // 登录 
const loginByUserName = (params) => request('post', `/auth/oauth2/token`, { user_type: 'C', deviceId: '12345678', grant_type: 'password', scope: 'server', ...params }); // 登录 
const loginByUserName1 = (params) => request('post', `/auth/oauth2/token`, { user_type: 'C', device_id: '12345678', grant_type: 'mobile', scope: 'server', ...params }); // 验证码登录 
const resetPwd = (params) => request('post', '/user/password/reset-password', params) //重置用户密码
const updatePwd = (params) => request('post', '/user/info/change-password', params) //修改用户密码
const sendVerificationCode = (params) => request('post', '/user/captcha/send-phone-captcha', params) //发送验证码
const sendCaptcha = (params, options) => request('post', '/auth/sms-login/send-captcha', params, options) //验证码登录发送验证码

const switchTenant = (params) => request('post', '/auth/public/switchTenant', params) //验证码登录-选组织二次登录


const permissionCollection = () => request('get', '/org/permission') //当前用户权限集合
const permissionCollectionTree = () => request('get', '/org/permission/tree') //当前用户权限树
const tenantInfo = (params) => request('get', '/oms/tenant/info', params) //根据域名获取组织信息

const getUserInfo = () => request('get', '/org/org-auth/afterLogin') // 获取用户信息菜单列表
const logOut = () => request('post', '/auth/token/logout') // 退出登录
const dpLogin = (params) => request('get', '/lms/libInfo/ldpLogin', params) //获取馆配商城token 

const getIndexData = (params) => request('get', '/org/org/index/data', params) //首页组织数据
const getIndexSysData = (params) => request('get', '/org/org/index/sysData', params) //首页系统数据


const getEduData = (params) => request('get', '/org/orgData/index/eduData', params) //首页学习教育数据
const getOrgPartBaseData = (params) => request('get', '/org/orgData/index/orgPartBaseData', params) //首页党支部基础数据
const getOrgBaseData = (params) => request('get', '/org/orgData/index/orgBaseData', params) //首页党委、总支基础数据
const getReadTrend = (params) => request('post', '/read/org-read-stat-day/oms/read-trend', params, { requestType: 'JSON' })//首页阅读趋势
const getRank = (params) => request('post', '/read/org-read-stat-day/oms/rank', params)//首页-支部阅读数据排行

const verifyInviteCode = (queryJson) => request('post', `/user/invite-register/verifyInviteCode?${queryString(queryJson)}`) // 首页打开验证
const inviteRegister = (queryJson, options) => request('post', `/user/invite-register/register?${queryString(queryJson)}`, {}, options) // 邀请注册
const emailRegister = (queryJson, options) => request('post', `/user/invite-register/register/email?`, queryJson, options) // 邀请注册

// 生成邀请链接
const inviteCodeGenerate = (params) => request('post', `/user/invite-register/oms/generate`, params)
// 获取邀请链接
const getCurrentLink = () => request('get', `/user/invite-register/oms/getCurrentLink`)

// 邀请H5返回app下载地址(只返回安
const inviteAppUrl = (queryJson, options) => request('post', `/org/org_app/invite-app-download-address?${queryString(queryJson)}`, {}, options)
const orgConfig = () => request('get', '/org/config/list') //获取组织参数
export const USER_API = {
  switchTenant,
  loginByUserName, // 登录
  loginByUserName1, // 验证码登录
  resetPwd,//重置用户密码
  sendVerificationCode,//发送验证码
  permissionCollection,//用户权限集合
  permissionCollectionTree,//权限树
  getUserInfo,//获取用户信息菜单列表
  logOut,// 退出登录
  dpLogin,
  getIndexData,
  getIndexSysData,
  getEduData,
  getOrgPartBaseData,
  getOrgBaseData,
  getReadTrend,
  getRank,
  tenantInfo,
  updatePwd,//修改密码
  verifyInviteCode,
  inviteRegister,
  emailRegister,
  inviteCodeGenerate,
  getCurrentLink,
  inviteAppUrl,
  sendCaptcha,
  orgConfig
}

